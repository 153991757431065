export default [
  // {
  //   key: 'image',
  //   initKey: 'profilePicture',
  //   label: 'field.image',
  //   type: 'async-single-image',
  //   rules: '',
  //   pw: 200,
  //   ph: 200,
  //   fullWidth: false,
  //   cols: 12,
  // },
  {
    key: 'name',
    label: 'field.name',
    rules: 'required|max:100',
    type: 'text',
  },
  {
    key: 'email',
    label: 'field.email',
    rules: 'required',
    type: 'text',
    cols: 12,
  },
]
